.App {
}

.ant-form-item {
  margin-bottom: 10px !important;
}

@media (max-width: 575px) {
  .ant-form-item-label > label {
    height: 0 !important;
    /* background-color: red; */
  }
}

.ant-upload-select {
    width: 100% !important;
}
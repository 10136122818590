.buttons {
    text-align: center;
    border-bottom: 1px solid #000;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
}

@media (max-width: 700px) {
  .buttons {
    flex-direction: column;
    gap: 1rem
  }
}

.buttons Button {
    margin-left: 20px;
}

/* .buttons * {
    margin-left: 20px;
} */
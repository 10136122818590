.login__container {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 900px;
  gap: 2rem;
  /* background-color: #fff; */
}

.login__header img {
  height: 170px;
  /* margin-top: 2rem; */
}

.login__header {
  position: relative;
  width: 100%;
  /* background-color: aqua; */
  align-self: center;
  text-align: center;
}

.login_lang_btn {
  position: absolute;
  /* background-color: red; */
  right: 0;
  bottom: 0
}

.login__body {
  margin: 0 auto;
  /* min-height: 50vh; */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 50px;
  height: 60vh;
  padding: 20px 0;
  width: 100%;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.login__form-container {
  width: 50%;
}

.alert {
  height: 20px;
}

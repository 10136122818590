.details {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.85rem;
}

.details h2 {
  text-align: center;
}

.details__body-container {
  /* background-color: #dedede; */
  max-height: 550px;
  overflow-y: auto;
}

.details__body--container-left {
  /* background-color: red; */
}

.section__container {
  border: 1px solid #efecec;
}

.section__row {
  padding-bottom: 1.2em;
}

.section__body {
  padding: 1.2em;
}

.section__container > div:first-child {
  height: 40px;
  /* text-align: center; */
  font-weight: bold;
  background-color: #F6F6F6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details__footer {
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* background-color: red; */
  
}

.details__footer > button {
  flex: 1;
  width: 100%;
  
}

.table {
    padding: 0 2rem;
}


.header {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0
}

.vendido {
    font-size: 2rem;
    font-weight: bold;
    color: green;
    text-align: center;
    margin: 0 auto; 
}

.table__actions {
    display: flex;
}
.table__actions >:first-child {
    margin-right: 30px;
}

@media (max-width: 700px) {
  .hidden {
    display: none;
  }
}
.filter h2 {
  text-align: center;
  /* margin-bottom: 50px; */
}

.count {
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
}

@media (min-width: 575px) {
  .hidden {
    display: none;
  }
}
